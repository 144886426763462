<script setup>
import { nextTick, onMounted, ref, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { validateForms, mobile, schemaMaker } from '@valid/JoiValidation'
import { searchCustomers } from '@service/CustomerService'

const emit = defineEmits(['done'])
const input = ref(null)
const store = useStore()
const route = useRoute()
const router = useRouter()
const btnLoading = ref(null)
const customerMobile = ref('')

// input autofocus on mounting
onMounted(() => {
  nextTick(() => {
    input.value.$el.querySelector('input').focus()
    setTimeout(() => window.scrollTo({ top: 400 }), 100)
  })
})
const validMobile = schemaMaker({
  mobile: mobile
})

const submitForm = async () => {
  if (btnLoading.value) return
  const errors = validateForms({ mobile: customerMobile.value }, validMobile)
  if (errors) return store.dispatch('displayErrors', errors)

  btnLoading.value = true
  const query = { type: 2, mobile: customerMobile.value }
  try {
    const { data } = await searchCustomers(query)
    if (!data.count) {
      store.dispatch('addToast', {
        type: 'error',
        message: 'مشتری با این شماره ثبت نشده است.'
      })
      emit('done')
    } else {
      const customerId = data.results[0].id
      if (route.name == 'CustomerShow') {
        store.dispatch('customer/clearItem')
        store.dispatch('customer/fetchItem', {
          part: 'customer',
          id: customerId
        })
        emit('done')
      } else router.push({ name: 'CustomerShow', params: { id: customerId } })
    }
  } catch (ex) {
    store.dispatch('addToast', {
      type: 'error',
      message: 'درخواست شما موفقیت‌آمیز نبود.'
    })
  } finally {
    btnLoading.value = false
  }
}
</script>

<template>
  <form novalidate class="modal-form-wrapper" @submit.prevent="submitForm">
    <div class="footer-modal">
      <h4 class="footer-modal__text">
        شماره مشتری
      </h4>
    </div>
    <BaseInput
      label="شماره مشتری"
      input-type="number"
      start-left
      phone-number
      ref="input"
      v-model="customerMobile"
    />
    <div class="credit-buttons">
      <button type="submit" class="button button--black">
        <svg
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd;width: 34px; height:34px"
          viewBox="0 0 512 512"
          class="button__icon"
          v-show="!btnLoading"
        >
          <path
            fill="#c69d4e"
            d="M489.4 508.14c5.14,5.14 13.6,5.15 18.74,0 5.15,-5.14 5.15,-13.6 0,-18.74l-135.98 -135.98 4.24 -5.12c16.92,-20.36 29.89,-43.69 38.19,-68.83 8.03,-24.31 11.58,-50.04 10.51,-75.62 -2.23,-53.42 -24.28,-103.75 -62.09,-141.57 -83.05,-83.04 -217.68,-83.04 -300.73,0 -83.04,83.05 -83.04,217.69 0,300.73 37.82,37.81 88.15,59.86 141.57,62.09 25.58,1.07 51.3,-2.48 75.62,-10.51 25.14,-8.3 48.46,-21.27 68.83,-38.19l5.11 -4.24 135.99 135.98zm-145.47 -426.78c72.51,72.51 72.51,190.07 0,262.57 -72.51,72.51 -190.06,72.51 -262.57,0 -72.5,-72.5 -72.5,-190.06 0,-262.57 72.51,-72.5 190.07,-72.5 262.57,0z"
          />
        </svg>

        <spinner v-if="btnLoading" />
      </button>
    </div>
  </form>
</template>
